<template>
  <el-header class="header-box one-div">
    <div class="header-content">
      <div class="header-left">
        <img class="header_img1" src="@/imgs/商标.png" />
        <h3 class="top_title1" v-if="upPresentFlag === false">
          <span class="areaMarket item">{{ areaValue }} </span>
          <span class="item">Electronic Product Information</span>
        </h3>
      </div>
      <div class="header-right">
        <el-link icon="el-icon-s-home" type="primary" @click="Home">
          <span v-if="isPC">HOME</span>
        </el-link>
      </div>
    </div>
  </el-header>
</template>
<script>
export default {
  data() {
    return {
      upPresentFlag: false,
      isPC: true
    };
  },
  props: {
    areaValue: {
      type: String,
      default: "",
    },
  },
  mounted() {
    // 当前设备屏幕尺寸
    window.onresize = () => {
      let width = window.innerWidth
      this.isPC = width > 1000;
    }
    window.onresize()
  },
  methods: {
    Home() {
      this.$router.push({ path: "/" });
    }
  },
};
</script>
<style lang="scss" scoped>
.header-box {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  text-align: center;
  border-bottom: 2px solid rgba(131, 128, 128, 0.1);
  font-family: "PingFangSC-Semibold, sans-serif";

  .header-content {
    width: 100%;

    .header-left {
      height: 100%;
      float: left;
      .header_img1 {
        display: inline-block;
        height: 0.85rem;
        margin-right: 0.2rem;
        vertical-align: middle;
      }
      h3 {
        display: inline-block;
        font-size: 0.45rem;
        align-self: center;
        font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
        // font-weight: lighter;
        vertical-align: middle;
        letter-spacing: 1px;
        color: #f8a60e;
        .areaMarket {
          text-align: left;
          color: #00478b;
          margin-bottom: 2px;
        }
        .item{
          line-height: 1em;
          display: block;
        }
      }
      // #00478b
    }
    .header-right {
      float: right;
      height: 100%;
      .el-link {
        width: 1.8rem;
        height: 0.65rem;
        border: 1px solid rgba(151, 150, 150, 0.274);
        border-radius: 4px;
        color: #00478b;
        font-size: 0.31rem;
        @media all and (max-width: 1000px) {
          border: none;
          width: 1rem;
          font-size: .8rem;
        }
      }
    }
  }
}
@media all and (max-width: 1000px) {
  .header-box {
    padding: 0.1rem 3%;
    font-weight: bold;
    h3 {
      font-size: 40px !important;
    }
  }
}
</style>
