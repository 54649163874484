
<template>
  <div>
    <!-- 电子说明书按照日期搜索历史版本 start -->
    <div class="main00">
      <h4>Search Result: Instructions for Use</h4>
    </div>
    <div class="main01">
      <div v-if="isPC">
        <el-table :data="detail" row-class-name="table-box" :header-cell-class-name="headerTableLast"
          :cell-class-name="headerTable" empty-text="No data"  key="1">
          <el-table-column prop="productName" label="Product name" min-width="180px">
            <template slot-scope="scope">
              <div style="cursor: pointer" @click="preView(scope.row,1)">
                {{ scope.row.productName }}
              </div>
            </template>
          </el-table-column>
          <el-table-column width="80px" prop="revisionDesp" label="Rev." show-overflow-tooltip> </el-table-column>
          <el-table-column width="130px" prop="validStartDate" label="Date of Publication"></el-table-column>
          <el-table-column min-width="120px" prop="productComment" label="Remark" show-overflow-tooltip>
          </el-table-column>
          <el-table-column prop="ifuCode" label="IFU code" min-width="90px">
            <template slot-scope="scope">
              <div style="cursor: pointer" @click="preView(scope.row,1)">
                {{ scope.row.ifuCode }}
              </div>
            </template>
          </el-table-column>
          <el-table-column icon="el-icon-s-home" label="IFU" class-name="btn-box" fixed="right" width="100px">
            <template slot="header">
              <div style="vertical-align: middle;">
                <span style="float: left;margin-top: 8px;">IFU</span>
                <img class="consultWhite" style="top: 0.05rem;" src="@/imgs/consultWhite.jpg" />
              </div>
            </template>
            <template slot-scope="scope">
              <el-button class="onlyStyleBtn" @click="Download(scope.row,1)" size="small"><i
                  class="el-icon-download el-icon--right"></i></el-button>
              <el-button class="onlyStyleBtn" size="small" @click="preView(scope.row,1)"><i
                  class="el-icon-view el-icon--right"></i></el-button>
              <!-- type="primary" -->
            </template>
          </el-table-column>
          <!--<el-table-column icon="el-icon-s-home" label="IFU" show-overflow-tooltip>-->
          <!--<div class="">-->
          <!--<el-button @click="Download(detail[0].ref, detail[0].publication_date)" size="small"><i class="el-icon-download el-icon&#45;&#45;right"></i></el-button>-->
          <!--<el-button type="primary" @click="preView(detail[0].ref, detail[0].publication_date)" size="small"><i class="el-icon-view el-icon&#45;&#45;right"></i></el-button>-->
          <!--</div>-->
          <!--</el-table-column>-->
        </el-table>
      </div>
      <div class="moblie-show-list" v-else>
        <div v-for="item in detail" :key="item.areaCode" class="moblie-for-list">
          <div class="moblie-item moblie-ifucode">
            <div class="moblie-title">IFU code</div>
            <div>{{ item.ifuCode }}</div>
          </div>
          <div class="moblie-item dencryption">
            <div class="moblie-title">Description</div>
            <div>{{ item.productName }}</div>
          </div>
          <div class="moblie-item remark">
            <div class="moblie-title">Remark</div>
            <div>{{ item.productComment }}</div>
          </div>
          <div class="moblie-item rev">
            <div class="moblie-title">Rev.</div>
            <div>{{ item.revisionDesp }}</div>
          </div>
          <div class="moblie-item date">
            <div class="moblie-title">Date of Publication</div>
            <div>{{ item.validStartDate }}</div>
          </div>
          <div class="moblie-item handle">
            <div class="moblie-title">
              <span style="float: left;margin-top: 8px;">IFU</span>
              <img class="consultWhite" style="top: 5px;" src="@/imgs/consultWhite.jpg" />
            </div>
            <div><span @click="preView(item,1)"><i class="el-icon-view el-icon--right"></i></span> <span
                @click="Download(item,1)"><i class="el-icon-download el-icon--right"></i></span></div>
          </div>
        </div>
      </div>
    </div>
    <!-- 电子说明书按照日期搜索历史版本 end -->

    <!-- 电子植入卡按照日期搜索历史版本 start -->
    <div class="main00" v-if="isShow && ecardDetail && ecardDetail.length > 0">
      <h4>Search Result: Product information</h4>
    </div>
    <div class="main01"  v-if="isShow && ecardDetail && ecardDetail.length > 0">
      <div v-if="isPC">
        <el-table :data="ecardDetail" row-class-name="table-box" :header-cell-class-name="headerTableLast"
          :cell-class-name="headerTable" empty-text="No data" key="2">
          <el-table-column prop="ecardName" label="Product name" min-width="180px">
            <template slot-scope="scope">
              <div style="cursor: pointer" @click="preView(scope.row,2)">
                {{ scope.row.ecardName }}
              </div>
            </template>
          </el-table-column>
          <el-table-column width="80px" prop="revisionDesp" label="Rev." show-overflow-tooltip> </el-table-column>
          <el-table-column width="130px" prop="validStartDate" label="Date of Publication"></el-table-column>
          <el-table-column min-width="120px" prop="productComment" label="Remark" show-overflow-tooltip>
          </el-table-column>
          <el-table-column prop="description" :label="showDescript?'Description':'          '" min-width="90px">
            <template slot-scope="scope">
              <div style="cursor: pointer" @click="preView(scope.row,2)">
                {{ scope.row.isShowDescript?scope.row.description:''}}
              </div>
            </template>
          </el-table-column>
          <el-table-column icon="el-icon-s-home" label="info" class-name="btn-box" fixed="right" width="100px">
            <template slot="header">
              <div>
                <span  style="float: left;margin-top: 8px;">info</span>
                <img class="consultWhite" style="top: 0.05rem;" src="@/imgs/pdf-file.svg" />
              </div>
            </template>
            <template slot-scope="scope">
              <el-button class="onlyStyleBtn" @click="Download(scope.row,2)" size="small"><i
                  class="el-icon-download el-icon--right"></i></el-button>
              <el-button class="onlyStyleBtn" size="small" @click="preView(scope.row,2)"><i
                  class="el-icon-view el-icon--right"></i></el-button>
              <!-- type="primary" -->
            </template>
          </el-table-column>
          <!--<el-table-column icon="el-icon-s-home" label="IFU" show-overflow-tooltip>-->
          <!--<div class="">-->
          <!--<el-button @click="Download(detail[0].ref, detail[0].publication_date)" size="small"><i class="el-icon-download el-icon&#45;&#45;right"></i></el-button>-->
          <!--<el-button type="primary" @click="preView(detail[0].ref, detail[0].publication_date)" size="small"><i class="el-icon-view el-icon&#45;&#45;right"></i></el-button>-->
          <!--</div>-->
          <!--</el-table-column>-->
        </el-table>
      </div>
      <div class="moblie-show-list" v-else>
        <div v-for="item in ecardDetail" :key="item.areaCode" class="moblie-for-list">
          <div class="moblie-item dencryption">
            <div class="moblie-title">Product name</div>
            <div>{{ item.ecardName }}</div>
          </div>
          <div class="moblie-item rev">
            <div class="moblie-title">Rev.</div>
            <div>{{ item.revisionDesp }}</div>
          </div>
          <div class="moblie-item date">
            <div class="moblie-title">Date of Publication</div>
            <div>{{ item.validStartDate }}</div>
          </div>
          <div class="moblie-item remark">
            <div class="moblie-title">Remark</div>
            <div>{{ item.productComment }}</div>
          </div>
          <div class="moblie-item moblie-ifucode" v-if="item.isShowDescript">
            <div class="moblie-title">Description</div>
            <div>{{ item.description }}</div>
          </div>
          <div class="moblie-item handle">
            <div class="moblie-title">
              <span style="float: left;margin-top: 8px;">info</span> 
              <img class="consultWhite" style="top: 5px;" src="@/imgs//pdf-file.svg" /></div>
            <div><span @click="preView(item,2)"><i class="el-icon-view el-icon--right"></i></span> <span
                @click="Download(item,2)"><i class="el-icon-download el-icon--right"></i></span></div>
          </div>
        </div>
      </div>
    </div>
    <!-- 电子植入卡按照日期搜索历史版本 end -->
    <Dialog v-bind="Dialog" @responseDialog="responseDialog"></Dialog>
  </div>
</template>
<script>
import Dialog from "./DialogShowFileInfo.vue"
import { pushDownload,pushEcardDownload } from "@/api/getData.js";
import { downloadPdf } from '@/utils/handle.js'
export default {
  components: {
    Dialog
  },
  props: {
    detail: {
      type: Array,
      required: true,
    },
    ecardDetail: {
      type: Array,
      required: true,
    },
    showDescript: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      dict:[],
      isShow:true,
      Dialog: {
        dialogState: false,
        URL: '',
        fileType: '',
        title: ''
      },
      isPC: true
    }
  },
  created() {
    this.dict = JSON.parse(window.sessionStorage.getItem('dict')?window.sessionStorage.getItem('dict'):[])
    let isShowList = this.dict.filter(item=>
      item.configKey == 'sys.web.isEpiHistoryVisible'
    )
    if(isShowList && isShowList.length > 0){
      this.isShow = (isShowList[0].configValue == '是')
    }
  },
  mounted() {
    // 当前设备屏幕尺寸
    window.onresize = () => {
      let width = window.innerWidth
      this.isPC = width > 1000;
    }
    window.onresize()
  },
  methods: {
    //下载当前页面下编号为ref，日期为publication_date 的器械的说明书
    async Download(row,value) {
      downloadPdf(row.filepath,value === 1?row.ebookCode:row.ecardCode)
      try {
        if (value === 1) {
          const { ifuCode, ebookId, ebookCode, revision, areaId, areaName, areaCode, groupCode, groupId, groupName, fileName, filepath } = row
          const data = await pushDownload({ ifuCode, ebookId, ebookCode, revision, areaId, areaName, areaCode, groupCode, groupId, groupName, fileName, filePath: filepath, type: "2" })
        }
        if (value === 2) {
          const { ifuCode, ebookId, ecardCode, revision, areaId, areaName, areaCode, groupCode, groupId, groupName, fileName, filepath } = row
          const data = await pushEcardDownload({ ifuCode, ecardId:ebookId, ecardCode, revision, areaId, areaName, areaCode, groupCode, groupId, groupName, fileName, filePath: filepath, type: "2" })
        }
      } catch { }
    },
    //对说明书进行预览
    async preView(row,value) {
      let url = row.filepath;
      try {
        if (value === 1) {
          const { ifuCode, ebookId, ebookCode, revision, areaId, areaName, areaCode, groupCode, groupId, groupName, fileName, filepath } = row
          const data = await pushDownload({ ifuCode, ebookId, ebookCode, revision, areaId, areaName, areaCode, groupCode, groupId, groupName, fileName, filePath: filepath, type: "1" })
        }
        if (value === 2) {
          const { ifuCode, ebookId, ecardCode, revision, areaId, areaName, areaCode, groupCode, groupId, groupName, fileName, filepath } = row
          const data = await pushEcardDownload({ ifuCode, ecardId:ebookId, ecardCode, revision, areaId, areaName, areaCode, groupCode, groupId, groupName, fileName, filePath: filepath, type: "1" })
        }
      } catch { }
      if (!this.isPC || window.navigator.msSaveBlob != undefined) {
        this.$router.push({
          path: "/ComPdf",
          query: {
            file: url,
          },
        });
        return false;
      }
      // pc端改为浏览器直接打开文件
      window.open(url);

      // this.Dialog.URL = url;
      // this.Dialog.title = row.productName;
      // this.Dialog.dialogState = true;
      // switch (url.slice(-4)) {
      //   case ".pdf":
      //     this.Dialog.fileType = "pdf";
      //     break;
      //   case ".mp3":
      //     this.Dialog.fileType = "mp3";
      //     break;
      //   case ".mp4":
      //     this.Dialog.fileType = "mp4";
      //     break;
      // }
    },
    // 表头class 过滤
    headerTable({ row, column, rowIndex, columnIndex }) {
      if (columnIndex == 0 || columnIndex == 5) {
        return 'headerTable'
      }
    },
    headerTableLast({ row, column, rowIndex, columnIndex }) {
      if (columnIndex == 0) {
        return 'headerTable'
      } else if (columnIndex == 5) {
        return 'headerTableLast'
      }
    },
    // 关闭Dialog
    responseDialog(val) {
      if (typeof val == 'boolean') { this.Dialog.dialogState = false; return; };
    }
  },
}
</script>

<style lang="scss" scoped>
.btn-box {
  display: flex;

  .el-button {
    margin: 0;
  }
}

.main00 {
  margin-top: 0.15rem;
  margin-bottom: 0.3rem;
  font-size: 0.3rem;
}

.main01 {
  border: 1px solid #ebeef5;

}

@media screen and (max-width: 769px) {}

@media screen and (min-width: 768px) {}

.moblie-show-list {
  background: white;
  font-family: PingFangSC-Regular, PingFang SC;

  .moblie-item+.moblie-item {
    border-top: 1px solid rgba(161, 160, 160, 0.171);
  }

  .moblie-item {
    font-size: 14px;
    line-height: 20px;
    color: #262626;
    display: flex;
    flex-direction: row;
    padding: 5px 10px;
    justify-content: space-around;

    div {
      flex: 1;
    }

    .moblie-title {
      font-weight: 400;
    }

    div+div {
      text-align: right;
    }
  }

  .handle {
    div+div {
      color: rgb(27, 154, 238);

      span {
        margin-left: 10px;
        display: inline-block;
        height: 100%;

        i {
          display: block;
          position: relative;
          top: 50%;
          transform: translateY(-50%);
        }
      }
    }
  }
}
</style>
