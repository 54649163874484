
<template>
  <div class="usa-child">
    <!-- 电子说明书搜索结果 start -->
    <div class="main00">
      <h4>Search Result: Instructions for Use</h4>
    </div>
    <div class="main01">
      <div v-if="isPC">
        <el-table :data="details" row-class-name="table-box" :header-cell-class-name="headerTableLast"
          :cell-class-name="headerTable" empty-text="No data" key="1">
          <el-table-column prop="productName" label="Product name" min-width="180px">
            <template slot-scope="scope">
              <div style="cursor: pointer" @click="preView(scope.row,1)">
                {{ scope.row.productName }}
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="revisionDesp" label="Rev." width="80px" show-overflow-tooltip>
          </el-table-column>
          <el-table-column prop="validStartDate" label="Date of Publication" width="130px"
            show-overflow-tooltip></el-table-column>
          <el-table-column prop="productComment" label="Remark" min-width="120px" show-overflow-tooltip>
          </el-table-column>
          <el-table-column prop="ifuCode" label="IFU code" min-width="90px">
            <template slot-scope="scope">
              <div style="cursor: pointer" @click="preView(scope.row,1)">
                {{ scope.row.ifuCode }}
              </div>
            </template>
          </el-table-column>
          <el-table-column icon="el-icon-s-home" label="IFU" class-name="btn-box" fixed="right" width="100px">
            <template slot="header">
              <div style="vertical-align: middle;">
                <span style="float: left;margin-top: 8px;">IFU</span>
                <img class="consultWhite" style="top: 0.05rem;" src="@/imgs/consultWhite.jpg" />
              </div>
            </template>
            <template slot-scope="scope">
              <el-button class="onlyStyleBtn" @click="Download(scope.row,1)" size="small"><i
                  class="el-icon-download el-icon--right"></i></el-button>
              <el-button class="onlyStyleBtn" size="small" @click="preView(scope.row,1)"><i
                  class="el-icon-view el-icon--right"></i></el-button>
              <!-- type="primary" -->
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="moblie-show-list" v-else>
        <div v-for="item in details" :key="item.areaCode" class="moblie-for-list">
          <div class="moblie-item dencryption">
            <div class="moblie-title">Product name</div>
            <div>{{ item.productName }}</div>
          </div>
          <div class="moblie-item rev">
            <div class="moblie-title">Rev.</div>
            <div>{{ item.revisionDesp }}</div>
          </div>
          <div class="moblie-item date">
            <div class="moblie-title">Date of Publication</div>
            <div>{{ item.validStartDate }}</div>
          </div>
          <div class="moblie-item remark">
            <div class="moblie-title">Remark</div>
            <div>{{ item.productComment }}</div>
          </div>
          <div class="moblie-item moblie-ifucode">
            <div class="moblie-title">IFU code</div>
            <div>{{ item.ifuCode }}</div>
          </div>
          <div class="moblie-item handle">
            <div class="moblie-title">
              <span style="float: left;margin-top: 8px;">IFU</span>
              <img class="consultWhite" style="top: 5px;" src="@/imgs/consultWhite.jpg" />
            </div>
            <div><span @click="preView(item,1)"><i class="el-icon-view el-icon--right"></i></span> <span
                @click="Download(item,1)"><i class="el-icon-download el-icon--right"></i></span></div>
          </div>
        </div>
      </div>
    </div>
    <!-- 电子说明书搜索结果 end -->

    <!-- 电子植入卡搜索结果 start -->
    <div class="main00" v-if="ecardDetails && ecardDetails.length > 0">
      <h4>Search Result: Product information</h4>
    </div>
    <div class="main01"  v-if="ecardDetails && ecardDetails.length > 0">
      <div v-if="isPC">
        <el-table :data="ecardDetails" row-class-name="table-box" :header-cell-class-name="headerTableLast"
          :cell-class-name="headerTable" empty-text="No data" key="2">
          <el-table-column prop="ecardName" label="Product name" min-width="180px">
            <template slot-scope="scope">
              <div style="cursor: pointer" @click="preView(scope.row,2)">
                {{ scope.row.ecardName }}
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="revisionDesp" label="Rev." width="80px" show-overflow-tooltip>
          </el-table-column>
          <el-table-column prop="validStartDate" label="Date of Publication" width="130px"
            show-overflow-tooltip></el-table-column>
          <el-table-column prop="productComment" label="Remark" min-width="120px" show-overflow-tooltip>
          </el-table-column>
          <el-table-column prop="description" :label="showDescriptIndex?'Description':'          '" min-width="90px">
            <template slot-scope="scope">
              <div style="cursor: pointer" @click="preView(scope.row,2)">
                {{ scope.row.isShowDescript?scope.row.description:''}}
              </div>
            </template>
          </el-table-column>
          <el-table-column icon="el-icon-s-home" label="info" class-name="btn-box" fixed="right" width="100px">
            <template slot="header">
              <div>
                <span  style="float: left;margin-top: 8px;">info</span>
                <img class="consultWhite" style="top: 0.05rem;" src="@/imgs/pdf-file.svg" />
              </div>
            </template>
            <template slot-scope="scope">
              <el-button class="onlyStyleBtn" @click="Download(scope.row,2)" size="small"><i
                  class="el-icon-download el-icon--right"></i></el-button>
              <el-button class="onlyStyleBtn" size="small" @click="preView(scope.row,2)"><i
                  class="el-icon-view el-icon--right"></i></el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="moblie-show-list" v-else>
        <div v-for="item in ecardDetails" :key="item.areaCode" class="moblie-for-list">
          <div class="moblie-item dencryption">
            <div class="moblie-title">Product name</div>
            <div>{{ item.ecardName }}</div>
          </div>
          <div class="moblie-item rev">
            <div class="moblie-title">Rev.</div>
            <div>{{ item.revisionDesp }}</div>
          </div>
          <div class="moblie-item date">
            <div class="moblie-title">Date of Publication</div>
            <div>{{ item.validStartDate }}</div>
          </div>
          <div class="moblie-item remark">
            <div class="moblie-title">Remark</div>
            <div>{{ item.productComment }}</div>
          </div>
          <div class="moblie-item moblie-ifucode"  v-if="item.isShowDescript">
            <div class="moblie-title">Description</div>
            <div>{{ item.description }}</div>
          </div>
          <div class="moblie-item handle">
            <div class="moblie-title">
              <span style="float: left;margin-top: 8px;">info</span> 
              <img class="consultWhite" style="top: 5px;" src="@/imgs//pdf-file.svg" /></div>
            <div><span @click="preView(item,2)"><i class="el-icon-view el-icon--right"></i></span> <span
                @click="Download(item,2)"><i class="el-icon-download el-icon--right"></i></span></div>
          </div>
        </div>
      </div>
    </div>
    <!-- 电子植入卡搜索结果 end -->

    <div class="main02">
      <!-- <el-card class="box-card"> -->
      <div class="text-item">
        NOTE: Please note that Product name / IFU code search does not necessarily yield the exact version of the IFU and/or 
        Product information for your specific product.  IFUs and/or Product information found using this approach should be 
        used for informational purposes only, and must not be used as a substitute for actual product labeling.
      </div>
      <!-- </el-card> -->
    </div>
    <div class="main03">
      <div class="text-content01">
        Refer to the exact version of the IFU and/or Product information by the date of manufacture on the label,
      </div>
      <div class="text-content02">
        To locate the date of manufacture, look on the product label for this symbol
        <img src="@/imgs/icon3.jpg" class="text-content-icon" style="padding: 0 8px" />.
      </div>
      <div class="search-Title">
        <h4>Date of Manufacture</h4>
        <div class="search-Form">
          <el-form ref="form" :model="form" @submit.native.prevent>
            <el-form-item prop="ProduceDate">
              <el-input v-model="form.ProduceDate" placeholder="YYYY-MM-DD"
                @keyup.enter.native="submitForm02()"></el-input>
            </el-form-item>
            <el-form-item>
              <div class="form007">
                <el-button @click="submitForm02()" size="small" type="primary">submit</el-button>
                <!--<el-button type="primary" @click="restForm02(form)" size="small">Reset</el-button>-->
              </div>
            </el-form-item>
          </el-form>
        </div>
      </div>
    </div>
    <!---->
    <div class="main04">
      <SearchResultForDate :detail="detail"  :ecardDetail="ecardDetail" :showDescript="showDescript" v-if="state === '1'"></SearchResultForDate>
    </div>
    <Dialog v-bind="Dialog" @responseDialog="responseDialog"></Dialog>
  </div>
</template>
<script>
import { getDetail, pushDownload,pushEcardDownload, searchCount,searchByParamsDate } from "@/api/getData.js";
import { downloadPdf } from '@/utils/handle.js'
import SearchResultForDate from "./SearchResultForDate";
import Dialog from "./DialogShowFileInfo.vue";
export default {
  components: {
    Dialog,
    SearchResultForDate,
  },
  props: {
    details: {
      type: Array,
      required: true,
    },
    ecardDetails: {
      type: Array,
      required: true,
    },
    showDescriptIndex: {
      type: Boolean,
      required: true,
    },
  },
  watch: {
    details: function () {
      this.state = "0";
      this.form.ProduceDate = "";
    },
    ecardDetails: function (newVal,oldVla) {
      this.state = "0";
      this.form.ProduceDate = "";
    },
  },

  data() {
    return {
      state: "0", //控制加载子页面
      form: {
        ProduceDate: "", //搜索框中的值
      },
      detail: [], //搜索后的数据
      ecardDetail: [],
      showDescript:false,
      url: "",
      Dialog: {
        dialogState: false,
        URL: "",
        fileType: "",
        title: "",
      },
      isPC: true
    };
  },
  created() {
  },
  mounted() {
    // 当前设备屏幕尺寸
    window.onresize = () => {
      let width = window.innerWidth
      this.isPC = width > 1000;
    }
    window.onresize()
  },

  methods: {
    //下载当前页面下编号为ref，日期为publication_date 的器械的说明书
    async Download(row,value) {
      this.ref = row.refCode;
      this.publication_date = row.createTime;
      downloadPdf(row.filepath, value === 1?row.ebookCode:row.ecardCode)
      try {
        if (value === 1) {
          const { ifuCode, ebookId, ebookCode, revision, areaId, areaName, areaCode, groupCode, groupId, groupName, fileName, filepath } = row
          const data = await pushDownload({ ifuCode, ebookId, ebookCode, revision, areaId, areaName, areaCode, groupCode, groupId, groupName, fileName, filePath: filepath, type: "2" })
        }
        if (value === 2) {
          const { ifuCode, ebookId, ecardCode, revision, areaId, areaName, areaCode, groupCode, groupId, groupName, fileName, filepath } = row
          const data = await pushEcardDownload({ ifuCode, ecardId:ebookId, ecardCode, revision, areaId, areaName, areaCode, groupCode, groupId, groupName, fileName, filePath: filepath, type: "2" })
        }
        // console.log(data)
      } catch { }
    },
    //对说明书进行预览
    async preView(row,value) {
      let url = row.filepath;
      // console.log("参数获取结果：" + url);
      if (!this.isPC || window.navigator.msSaveBlob != undefined) {
        this.$router.push({
          path: "/ComPdf",
          query: {
            file: url,
          },
        });
      }else{
        // pc端改为浏览器直接打开文件
        window.open(url);
      }

      

      // this.Dialog.URL = url;
      // this.Dialog.title = row.productName;
      // this.Dialog.dialogState = true;
      // switch (url.slice(-4)) {
      //   case ".pdf":
      //     this.Dialog.fileType = "pdf";
      //     break;
      //   case ".mp3":
      //     this.Dialog.fileType = "mp3";
      //     break;
      //   case ".mp4":
      //     this.Dialog.fileType = "mp4";
      //     break;
      // }
      try {
        if (value === 1) {
          const { ifuCode, ebookId, ebookCode, revision, areaId, areaName, areaCode, groupCode, groupId, groupName, fileName, filepath } = row
          const data = await pushDownload({ ifuCode, ebookId, ebookCode, revision, areaId, areaName, areaCode, groupCode, groupId, groupName, fileName, filePath: filepath, type: "1" })
        }
        if (value === 2) {
          const { ifuCode, ebookId, ecardCode, revision, areaId, areaName, areaCode, groupCode, groupId, groupName, fileName, filepath } = row
          const data = await pushEcardDownload({ ifuCode, ecardId:ebookId, ecardCode, revision, areaId, areaName, areaCode, groupCode, groupId, groupName, fileName, filePath: filepath, type: "1" })
        }
      } catch { }
    },
    // 时间戳数据请求
    async getTimeQuery(data) {
      try {
        const results = await searchByParamsDate(data);
        if (results.status == 200) {
          console.log(results,'results')
          this.state = "1";
          this.detail = results.data.data.ebook;
          this.ecardDetail = results.data.data.ecard;

          // 标题显示不显示，取决于有没有内容
          // 内容显示不显示，取决于有没有多个相同的产品名称
          for (let i = 0; i < this.ecardDetail.length; i++) {
            let filterList = this.ecardDetail.filter(item=> item.ecard_name === this.ecardDetail[i].ecard_name)
            if (filterList.length > 1) {
              this.ecardDetail[i].isShowDescript = true
            }
          }
          this.showDescript = this.ecardDetail.some(item=>item.isShowDescript)

          this.$emit('updateScroll')
        } else {
          this.$message.error(details.data.msg);
        }
      } catch {
        // console.error("数据请求异常！");
      }
    },
    // 搜索量统计
    async pushSearchCount(data) {
      try {
        const res = await searchCount(data)
        // console.log(res)
      } catch {

      }
    },
    // 表头class 过滤
    headerTable({ row, column, rowIndex, columnIndex }) {
      if (columnIndex == 0 || columnIndex == 5) {
        return 'headerTable'
      }
    },
    headerTableLast({ row, column, rowIndex, columnIndex }) {
      if (columnIndex == 0) {
        return 'headerTable'
      } else if (columnIndex == 5) {
        return 'headerTableLast'
      } else if (columnIndex == 1) {
        return 'textNotBold'
      }
    },

    // 提交表单
    submitForm02() {
      let exp =
        /([0-9]{3}[1-9]|[0-9]{2}[1-9][0-9]{1}|[0-9]{1}[1-9][0-9]{2}|[1-9][0-9]{3})-(((0[13578]|1[02])-(0[1-9]|[12][0-9]|3[01]))|((0[469]|11)-(0[1-9]|[12][0-9]|30))|(02-(0[1-9]|[1][0-9]|2[0-8])))/;
      if (this.form.ProduceDate != "" && (this.details.length > 0 || this.ecardDetails.length > 0 )) {
        if (exp.test(this.form.ProduceDate)) {
          let data = {}
          data.ebookList = this.details
          data.ecardList = this.ecardDetails
          data.dateTime = this.form.ProduceDate

          this.getTimeQuery(data);
          this.pushSearchCount({ areaCode: this.$route.query.areaCode, manufactureDate: this.form.ProduceDate })
        } else {
          this.$message({
            message: "Please enter a valid 'Date of Manufacture'.",
            type: "warning",
          });
        }
      } else {
        if (this.details && this.details.length > 0) {
          this.$message({
            message: "Please enter a valid 'Date of Manufacture'.",
            type: "warning",
          });
        } else {
          this.$message({
            message: "Please enter a valid 'Product name / IFU code'.",
            type: "error",
          });
        }
      }
    },
    //重置输入框中的内容
    restForm02() {
      // console.log(form);
      this.$refs.form.resetFields();
    },
    // 关闭Dialog
    responseDialog(val) {
      if (typeof val == "boolean") {
        this.Dialog.dialogState = false;
        return;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.usa-child {
  // border:1px solid rgba(151, 150, 150, 0.274);
  padding: 0.3rem;
  border-radius: 0.1rem;
}
.text-item {
  background: #edf4fa;
}

.form01 {
  margin-right: 0rem;
}
.main00 {
  margin-top: 0.15rem;
  margin-bottom: 0.3rem;
  font-size: 0.3rem;
}
.main01 {
  border: 1px solid #ebeef5;
}

.table-box {
  font-size: 0.35rem;
}

.btn-box {
  display: flex;

  .el-button {
    margin: 0;
  }
}

.search-Form .el-form {
  display: flex;
  flex-direction: row;
}

.search-Form .el-form .el-form-item:first-child {
  flex-grow: 2;
}

.search-Form .el-form .el-form-item:last-child {
  flex-grow: 1;
}

.text-content-icon {
  width: 25px;
  height: 24px;
}

@media screen and (max-width: 768px) {
  .text-content-icon {
    width: 18px;
    height: 15px;
  }

  .text-item {
    font-size: 0.35rem;
  }

  .main01 {
    margin-top: 0.1rem;
  }

  .main02 {
    font-size: 0.35rem;
    margin-top: 0.25rem;
  }

  .main03 {
    font-size: 0.35rem;
    margin-top: 0.3rem;
  }

  .text-content02 {
    // display: flex;
    margin-top: 0.15rem;
  }

  .search-Title {
    margin-top: 1.2rem;
    font-size: 0.4rem;
  }

  .search-Form .el-form .el-form-item:first-child {
    flex: 5;
  }

  .search-Form .el-form .el-form-item:last-child {
    flex: 1;
  }
}

@media screen and (min-width: 769px) {
  .main01 {
    margin-top: 10px;
  }

  .main02 {
    font-size: 18px;
    margin-top: 10px;
    line-height: 24px;
  }

  .text-item {
    line-height: 25px;
    background: #FEE8E8;
    color: #738898;
    padding: .1.1rem 0;
    padding-left: 0.46rem;
  }

  .main03 {
    padding-top: .1rem;
  }

  .text-content01,
  .text-content02 {
    // display: flex;
    font-size: 18px;
    line-height: 24px;
    color: #545556;
    margin-top: .26rem;
  }

  .search-Title {
    font-size: 18px;

    h4 {
      padding: .2rem 0;
    }
  }

  .form01 {
    margin-right: 0px;
  }
}

.moblie-show-list {
  background: white;
  font-family: PingFangSC-Regular, PingFang SC;

  .moblie-item+.moblie-item {
    border-top: 1px solid rgba(161, 160, 160, 0.171);
  }

  .moblie-item {
    font-size: 14px;
    line-height: 20px;
    color: #262626;
    display: flex;
    flex-direction: row;
    padding: 5px 10px;
    justify-content: space-around;

    div {
      flex: 1;
    }

    .moblie-title {
      font-weight: 400;
    }

    div+div {
      text-align: right;
    }
  }

  .handle {
    div+div {
      color: rgb(27, 154, 238);

      span {
        margin-left: 10px;
        display: inline-block;
        height: 100%;

        i {
          display: block;
          position: relative;
          top: 50%;
          transform: translateY(-50%);
        }
      }
    }
  }
}
</style>
