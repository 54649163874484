import axios from "axios";

export function downloadPdf(file, name) {
    let u = navigator.userAgent;
    let isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);     //判断是否是 iOS终端
    axios({
        method: "get",
        url: file,
        responseType: "blob"
    }).then(function(response) {
        // console.log("11111111" + response)
        const link = document.createElement("a");
        let blob = new Blob([response.data], { type: 'application/octet-stream;charset=UTF-8' });
        if (window.navigator.msSaveBlob != undefined) {
            window.navigator.msSaveBlob(blob, `${name}.pdf`)
        } else {
            // console.log("2222222" + blob)
            let url = URL.createObjectURL(blob);
            link.href = url;
            // link.href = file;
            link.setAttribute('download', name.split(".")[0] + ".pdf")
            link.setAttribute('target', '_blank')
            document.body.appendChild(link)
            link.click();
            document.body.removeChild(link)
            window.URL.revokeObjectURL(url)

        }
    }).catch(err => {
        // console.log("下载错误" + err)
    })
}